export default [{
  header: 'Les catalogues',
  icon: 'BookOpenIcon',
  children: [
    {
      title: 'Le personnel',
      icon: 'UserIcon',
      route: 'Liste-ouvrier',
    },
    {
      title: 'Les prix',
      route: 'Liste-prix',
      icon: 'DollarSignIcon',
    },
    {
      title: 'Les produits',
      route: 'Liste-produit',
      icon: 'PackageIcon',
    },
    {
      title: 'Les ouvrages',
      route: 'catalogue',
      icon: 'BriefcaseIcon',
    },
   
  ],
},
]
