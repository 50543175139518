export default [
    {
        header: 'Devis',
        icon: 'FileTextIcon',
        children: [
        {
          title: 'Liste',
          route: 'devis',
        },
        {
          title: 'Ajouter',
          route: { name: 'devis-add' },
        },
      ],
    },
    {
       header: 'Planning',
      icon: 'FileTextIcon',
      children: [
        {
          title: 'general',
          route: 'devisss',
        },
        {
          title: 'Chantier',
          route: { name: 'planning-chantier' },
        },
      ],
    },
  ]
  